<template>

<app-content :loading="is.loading || is.checking" :fullheight="true">

	<app-content-head title="Checkout" :is-app="true" app-action="scan" v-on:action="onActionClick" />

	<app-content-body :no-padding="true" class="dashboard">

		<com-scan v-if="is.scanning" v-on:close="is.scanning = false" :allowClose="true" :allowManual="true" v-on:scanned="onScanDetected" :class="{'is-failed': is.failed}" />

		<div class="dashboard-results-prompt" v-if="!items.length" v-on:click="is.scanning = true">

			<app-icon icon="scan" />
			<p>Scan game<small>Tap to use camera</small></p>

		</div>

		<com-loan v-for="item in items" :loan="item" :key="item.id" />

	</app-content-body>

</app-content>

</template>

<script>

export default {

	components: {
		'com-scan': () => import('./common/Scan'),
		'com-loan': () => import('./checkoutdashboard/Loan')
	},

	data: function() {

		return {
			is: {
				scanning: false,
				checking: false,
				loading: true
			},
			items: []
		}

	},

	created: function() {

		this.$pubsub.$on('scan', this.onScanDetected)

		this.load()

	},
	
	beforeDestroy: function() {

		this.$pubsub.$off('scan', this.onScanDetected)

	},

	methods: {
		
		onActionClick: function() {

			this.is.scanning = true

		},

		load: function() {

			this.$api.get('checkout/loans', {
				status: 1
			}).then(function(json) {

				this.items = json.collection 

				this.is.loading = false

			}.bind(this))

		},

		onScanDetected: function(code) {

			this.is.checking = true

			this.$api.get(['checkout/game', code]).then(function(json) {

				this.$router.push({
					name: 'Checkout.Loan',
					params: {
						id: (json.loan) ? json.loan : 'new'
					},
					query: {
						game: (json.loan) ? undefined : json.game,
						scanned: 1
					}
				})

				this.is.checking = false

			}.bind(this), function() {

				this.is.checking = false

				this.$notify({
					message: 'Unrecognised game',
					type: 'error'
				})

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.dashboard {
	background-color: #fff;
}

.dashboard-results-prompt {
	color: #9aa2ac;
	position: absolute;
	left: 50%;
	text-align: center;
	top: 50%;
	transform: translate(-50%, -50%);
	cursor: pointer;
}

.dashboard-results-prompt p small {
	display: block;
	font-size: 12px;
	font-weight: 300;
}

.dashboard.is-failed .dashboard-results-prompt {
	color: #fff;
}

.dashboard-results-prompt >>> .icon {
	font-size: 64px;
	margin-bottom: 10px;
}

.dashboard-results-prompt >>> p {
	font-size: 16px;
	line-height: 20px;
	font-weight: 400;
}

.is-desktop .dashboard-results-prompt:hover {
	color: #4d5b6e;
}

</style>
